import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import { MainLayout } from '../../components/MainLayout';
import SectionWrapper from '../../components/Common/SectionWrapper';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';
import { useI18next, useTranslation } from 'gatsby-plugin-react-i18next';
import * as S from './ArticleTemplate.styles';
import { StaticImage } from 'gatsby-plugin-image';
import WaveBigIcon from '../../components/Common/Icons/WaveBigIcon';
import routes from '../../static/constants/routes';
import InstaIcon from '../../components/Common/Icons/InstaIcon';
import { theme } from '../../styles/theme';
import PodcastLinkButton from '../../components/Common/Buttons/PodcastLinkButton';
import SpotifyIcon from '../../components/Common/Icons/SpotifyIcon';
import { externalLinks } from '../../static/constants/externalLinks';
import ApplePodcastsIcon from '../../components/Common/Icons/ApplePodcastsIcon';
import YouTubeIcon from '../../components/Common/Icons/YouTubeIcon';
import GooglePodcastsIcon from '../../components/Common/Icons/GooglePodcastsIcon';
import BookPromotionBanner from '../../components/BookPromotionBanner';
import { SEO } from '../../components/SEO';

function calculateReadingTime(content: string): number {
  const wordsPerMinute = 200;

  const wordCount = content.trim().split(/\s+/).length;

  const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute);

  return readingTimeMinutes;
}

const getArticle = (data: any, lang: string) => {
  const articles: Article[] = data.allContentfulArticle.nodes.map((art) => {
    const article: Article = {
      title: art.title,
      description: art.description,
      slug: art.slug,
      locale: art.node_locale,
      createdAt: new Date(art.createdAt),
      body: art.body.body,
      contentfulId: art.contentful_id,
    };

    return article;
  });

  const article = articles.find((art) => art.locale === lang);

  return article;
};

interface Article {
  title: string;
  body: string;
  locale: string;
  slug: string;
  createdAt: Date;
  description: string;
  contentfulId: string;
}

const ArticleTemplate = ({ data }) => {
  const { t } = useTranslation();
  const { i18n } = useI18next();
  const activeLang = i18n.resolvedLanguage;

  const article = getArticle(data, activeLang);

  if (!article) {
    return <></>;
  }

  return (
    <MainLayout>
      <BookPromotionBanner />
      <SectionWrapper removePaddingVerticalDesktop>
        <S.IntroSection>
          <S.AuthorSection>
            <S.ImageWrapper>
              <StaticImage
                src="../../assets/images/maria-kmita-round.png"
                alt="maria-kmita-round"
                placeholder="blurred"
              />
            </S.ImageWrapper>
            <S.AuthorWrapper>
              <S.AuthorName>{t('blog_article_author_name')}</S.AuthorName>
              <S.AuthorRole>{`- ${t(
                'blog_article_author_role',
              )}`}</S.AuthorRole>
            </S.AuthorWrapper>

            <S.WaveIconWrapper>
              <WaveBigIcon height={120} />
            </S.WaveIconWrapper>
          </S.AuthorSection>

          <S.ContentDataSection>
            <S.CreatedTime>
              {`${t(
                'blog_article_created_at',
              )}: ${article.createdAt.toLocaleDateString()}`}
            </S.CreatedTime>
            <S.ReadTime>
              {`${t('blog_article_read_time')} ${calculateReadingTime(
                article.body,
              )} ${t('blog_article_read_time_unit')}`}
            </S.ReadTime>
          </S.ContentDataSection>
        </S.IntroSection>

        <S.ContentSection>
          <S.Title>{article.title}</S.Title>
          <S.BodyWrapper>
            <ReactMarkdown>{article.body}</ReactMarkdown>
          </S.BodyWrapper>
        </S.ContentSection>

        <S.SummarySection>
          <S.SummaryMarketingText>
            {t('blog_article_footer_text')}
          </S.SummaryMarketingText>

          <S.InstaWrapperLink
            href={externalLinks.insta}
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstaIcon color={theme.color.textPrimary} />
            <S.SummaryTextWrapper>
              <S.SummaryText> {t('blog_article_footer_insta')}</S.SummaryText>
            </S.SummaryTextWrapper>
          </S.InstaWrapperLink>

          <S.PodcastWrapper>
            <S.SummaryTextWrapper>
              <S.SummaryText>{t('blog_article_footer_podcast')}</S.SummaryText>
            </S.SummaryTextWrapper>
            <PodcastLinkButton routeTo={externalLinks.podcast.spotify}>
              <SpotifyIcon />
            </PodcastLinkButton>
            <PodcastLinkButton routeTo={externalLinks.podcast.apple}>
              <ApplePodcastsIcon />
            </PodcastLinkButton>

            <PodcastLinkButton routeTo={externalLinks.podcast.googlePodcasts}>
              <GooglePodcastsIcon />
            </PodcastLinkButton>
            <PodcastLinkButton routeTo={externalLinks.youTube}>
              <YouTubeIcon />
            </PodcastLinkButton>
          </S.PodcastWrapper>
        </S.SummarySection>
      </SectionWrapper>
    </MainLayout>
  );
};

export default ArticleTemplate;

export const Head = ({ pageContext, data }) => {
  const activeLang = pageContext.language;

  const article = getArticle(data, activeLang);

  const title = article?.title;
  const description = article?.description;

  return (
    <SEO
      title={title}
      description={description}
      pathname={`${routes.blog}/${article.slug}_${article.contentfulId}`}
    />
  );
};

export const query = graphql`
  query LocalesQuery($language: String!, $articleId: String) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    allContentfulArticle(filter: { contentful_id: { eq: $articleId } }) {
      nodes {
        body {
          body
        }
        node_locale
        slug
        title
        createdAt
        description
        contentful_id
      }
    }
  }
`;
