import styled from 'styled-components';
import { theme } from '../../styles/theme';
import { query } from '../../styles/mediaQuery';

export const IntroSection = styled.div`
  margin-top: 3.6rem;
`;

export const AuthorSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  position: relative;

  @media ${query.desktop} {
    flex-direction: row;
  }
`;

export const AuthorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const AuthorName = styled.p`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 2.8rem;
  font-weight: 600;
  margin: 0;

  @media ${query.desktop} {
    font-size: 3.6rem;
  }
`;

export const AuthorRole = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: ${({ theme }) => theme.font.size.paragraph};
  font-weight: 400;
  margin: 1.2rem 0 0;
`;

export const ImageWrapper = styled.div`
  max-width: 20rem;
  max-height: 20rem;
  align-self: center;
`;

export const WaveIconWrapper = styled.div`
  position: absolute;
  left: 20rem;
  top: 50%;
  transform: translateY(-50%);

  display: none;

  @media ${query.desktop} {
    display: block;
  }
`;

export const ContentDataSection = styled.div`
  display: flex;
  flex-direction: column;
  border-top: ${`1px solid ${theme.color.inputBorder}`};
  border-bottom: ${`1px solid ${theme.color.inputBorder}`};
  padding: 2.4rem 0;
  margin-top: 2.4rem;
`;

export const CreatedTime = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};
  opacity: 50%;
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 2.4rem;
  font-weight: 600;
  margin: 0;
`;

export const ReadTime = styled.p`
  color: ${({ theme }) => theme.color.textSecondary};

  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 2rem;
  font-weight: 600;
  margin: 1rem 0 0;
`;

export const ContentSection = styled.div`
  max-width: 860px;
  margin: 5.4rem auto;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.color.textSecondary};
  font-family: ${({ theme }) => theme.font.family.archivo};
  font-size: 3.6rem;
  font-weight: 800;
  margin: 3.6rem 0 5.4rem;

  @media ${query.desktop} {
    font-size: 5.4rem;
  }
`;

export const BodyWrapper = styled.div`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};

  & h2 {
    font-size: 3.2rem;
  }

  & p {
    font-size: 1.6rem;

    @media ${query.desktop} {
      font-size: 1.8rem;
    }
  }

  & li {
    font-size: 1.6rem;
    list-style-type: disc;
    list-style-position: inside;
    line-height: 2.8rem;

    @media ${query.desktop} {
      font-size: 1.8rem;
    }
  }

  & strong {
    color: ${theme.color.primaryOrange};
  }
`;

export const SummarySection = styled.div`
  width: fit-content;
  margin: 0 auto 5.4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SummaryMarketingText = styled.h5`
  color: ${({ theme }) => theme.color.primaryOrange};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 2.4rem;
  font-weight: 600;
  margin: 3.6rem auto 3.6rem;
  text-align: center;
`;

export const InstaWrapperLink = styled.a`
  display: flex;
  gap: 1.6rem;
  justify-content: center;
  align-items: center;

  text-decoration: none;
`;

export const SummaryTextWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SummaryText = styled.p`
  color: ${({ theme }) => theme.color.textPrimary};
  font-family: ${({ theme }) => theme.font.family.workSans};
  font-size: 2rem;
  font-weight: 600;
  margin: 0 auto;
`;

export const PodcastWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  justify-content: center;
  align-items: center;
  margin-top: 3.2rem;
`;
