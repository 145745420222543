import React from 'react';
import { theme } from '../../../styles/theme';

interface InstaIconProps {
  color?: string;
  width?: number;
  height?: number;
}

const InstaIcon = ({ color, width, height }: InstaIconProps) => {
  return (
    <svg
      width={width ?? '29'}
      height={height ?? '28'}
      viewBox="0 0 29 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.2447 9.30843C28.2447 12.5846 28.2447 15.8607 28.2447 19.1369C28.2082 19.5022 28.1838 19.8554 28.1594 20.2208C28.0255 22.0963 27.5505 23.8379 26.2717 25.2751C24.7737 26.9679 22.8251 27.723 20.645 27.7596C16.6138 27.8326 12.5704 27.8205 8.53913 27.7596C6.62703 27.7352 4.87326 27.1263 3.42396 25.7988C1.76762 24.2642 1.0856 22.3034 1.03689 20.1234C0.951632 17.0177 0.951632 13.8999 0.939453 10.7943C0.939453 9.66163 1.00035 8.5168 1.10996 7.38416C1.48751 3.87662 3.80151 1.41647 7.28469 0.807517C8.07633 0.673548 8.88014 0.637011 9.67177 0.551758C12.9479 0.551758 16.2241 0.551758 19.5124 0.551758C20.1457 0.612653 20.7668 0.661368 21.4001 0.722263C24.932 1.11199 27.3922 3.41382 28.0011 6.90918C28.1229 7.70081 28.1594 8.50462 28.2447 9.30843ZM14.5799 25.3238C16.492 25.3238 18.4041 25.3847 20.3162 25.3116C23.6045 25.1776 25.407 23.5578 25.6262 20.3304C25.9063 16.1408 25.9063 11.9269 25.5897 7.73735C25.4923 6.40984 24.9686 5.24066 23.9212 4.36378C22.8372 3.45035 21.5341 3.18242 20.1822 3.14588C17.4054 3.08499 14.6286 3.06063 11.8518 3.06063C10.5974 3.06063 9.33076 3.09716 8.07633 3.23113C5.47003 3.49907 3.83805 5.13105 3.57011 7.73735C3.44832 8.95525 3.3996 10.1853 3.3996 11.4154C3.3996 14.2531 3.38742 17.0908 3.48486 19.9285C3.60665 23.3873 5.42131 25.202 8.88014 25.3238C10.7922 25.3847 12.68 25.3238 14.5799 25.3238Z"
        fill={color ?? '#FDFDFD'}
      />
      <path
        d="M14.6039 21.2455C10.6944 21.2455 7.56445 18.1277 7.56445 14.2304C7.56445 10.3331 10.6823 7.20312 14.5917 7.20312C18.489 7.20312 21.619 10.3331 21.6068 14.2304C21.6068 18.1155 18.4768 21.2333 14.6039 21.2455ZM10.0368 14.206C10.0246 16.7027 12.0707 18.761 14.5795 18.761C17.064 18.761 19.1101 16.7393 19.1345 14.2547C19.1588 11.7459 17.1128 9.68763 14.6161 9.67545C12.1072 9.66327 10.049 11.7093 10.0368 14.206Z"
        fill={color ?? '#FDFDFD'}
      />
      <path
        d="M23.5193 6.92217C23.5193 7.8356 22.7885 8.57851 21.8751 8.57851C20.9617 8.57851 20.2188 7.84777 20.2188 6.93435C20.2188 6.02093 20.9495 5.29019 21.8629 5.29019C22.7885 5.27801 23.5193 6.00875 23.5193 6.92217Z"
        fill={color ?? '#FDFDFD'}
      />
    </svg>
  );
};

export default InstaIcon;
